<script>
import { ref, computed } from 'vue'
import { formatDollar, formatAmount } from '/~/utils/format/money'
import { roundFigure } from '/~/utils/format/numeric'
import { formatPoints } from '/~/utils/points'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseRange from '/~/components/base/range/base-range.vue'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { usePoints } from '/~/composables/points'

export default {
  name: 'payments-points-form-v2',
  components: {
    BaseRange,
    BaseButton,
    BaseIcon,
  },
  props: {
    initialValue: {
      type: Number,
      default: 0,
    },
    remainingTotal: {
      type: Number,
      default: 0,
    },
    flowType: {
      type: String,
      default: FlowType.purchase,
      validator: (v) => !v || Object.values(FlowType).includes(v),
    },
  },
  setup(props, { emit }) {
    const {
      pointsBalance,
      burnPointsRatePaymentOrder,
      burnPointsRateBatchOrder,
      burnPointsRateStatementOrder,
      burnPointsRateOrder,
    } = usePoints()

    const pointsToUse = ref(props.initialValue)

    const burnPointsRate = computed(() => {
      switch (props.flowType) {
        case FlowType.payment:
          return burnPointsRatePaymentOrder.value
        case FlowType.batch:
          return burnPointsRateBatchOrder.value
        case FlowType.statement:
          return burnPointsRateStatementOrder.value
      }

      return burnPointsRateOrder.value
    })

    const totalPointsAmount = computed(() =>
      roundFigure(pointsBalance.value * burnPointsRate.value)
    )

    const pointsMaxToUse = computed(() => {
      const maxPoints = Math.ceil(props.remainingTotal / burnPointsRate.value)

      return Math.min(maxPoints, Math.max(pointsBalance.value - 1, 0))
    })

    const calculatedAmount = computed(() => {
      const amount = roundFigure(pointsToUse.value * burnPointsRate.value)

      return Math.min(props.remainingTotal, amount)
    })

    const amountToPay = computed(
      () => props.remainingTotal - calculatedAmount.value
    )

    const isConfirmDisabled = computed(() => {
      return pointsToUse.value < 1
    })

    function onConfirm() {
      emit('confirm', {
        selected: pointsToUse.value,
        calculatedAmount: calculatedAmount.value,
      })
    }

    function onCancel() {
      emit('cancel')
    }

    return {
      formatDollar,
      formatPoints,
      formatAmount,
      pointsBalance,
      totalPointsAmount,
      pointsToUse,
      pointsMaxToUse,
      amountToPay,
      isConfirmDisabled,
      onConfirm,
      onCancel,
    }
  },
}
</script>

<template>
  <div
    class="mt-12 flex w-full flex-col items-center justify-center md:mt-0 md:space-y-6"
  >
    <div class="flex flex-col items-center">
      <base-icon svg="symbion/token-sqr" class="text-primary" :size="100" />
    </div>

    <div class="flex w-full items-center">
      <div class="mr-5 sm:-mt-1">
        <base-icon svg="symbion/token-sqr" class="text-primary" :size="48" />
      </div>

      <div class="flex grow flex-col">
        <div class="flex justify-between text-eonx-neutral-600">
          <div>Points balance available</div>
          <div>Value</div>
        </div>

        <div class="flex justify-between pt-1 font-bold text-eonx-neutral-800">
          <div>
            {{ formatPoints(pointsBalance) }}
          </div>
          <div>{{ formatDollar(totalPointsAmount) }}</div>
        </div>
      </div>
    </div>

    <base-range
      v-model="pointsToUse"
      class="w-full pt-[17px]"
      :min="0"
      :dot-size="32"
      placement="top"
      formatter="{value}"
      :max="pointsMaxToUse"
    />

    <div class="flex w-full flex-col items-center py-3 text-center">
      <span class="mb-1.5 text-sm font-bold leading-5 text-eonx-neutral-600">
        Remaining to pay
      </span>
      <div class="relative pl-3 leading-none">
        <span class="absolute -left-[3px] top-0 text-lg font-bold">$</span>
        <strong class="text-5xl text-eonx-neutral-800">
          {{ formatAmount(amountToPay) }}
        </strong>
      </div>
    </div>

    <div class="mt-7 flex w-full justify-center pt-3">
      <base-button
        data-testid="confirmButton"
        class="md:w-64"
        :disabled="isConfirmDisabled"
        @click="onConfirm"
      >
        <span class="text-sm">Confirm</span>
      </base-button>
    </div>
  </div>
</template>
