<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import PaymentsPointsForm from '/~/components/payments/points/payments-points-form.mobile.vue'
import CheckoutPointsDesktop from './checkout-points.desktop.vue'

export default {
  name: 'checkout-points-mobile',
  components: {
    BaseAsidePage,
    PaymentsPointsForm,
  },
  extends: CheckoutPointsDesktop,
  setup() {
    return {
      ...CheckoutPointsDesktop.setup?.(...arguments),
    }
  },
}
</script>

<template>
  <base-aside-page title="Pay with points" no-padding shadow :back="redirect">
    <payments-points-form
      :initial-value="initialValue"
      :remaining-total="remainingTotal"
      @cancel="onCancel"
      @confirm="onConfirm"
    />
  </base-aside-page>
</template>
