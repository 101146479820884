<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router/composables'
import { formatDollar, formatAmount } from '/~/utils/format/money'
import { roundFigure } from '/~/utils/format/numeric'
import { formatPoints } from '/~/utils/points'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseRange from '/~/components/base/range/base-range.vue'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { usePoints } from '/~/composables/points'

const props = withDefaults(
  defineProps<{
    initialValue?: number
    remainingTotal?: number
    flowType?: FlowType
  }>(),
  {
    initialValue: 0,
    remainingTotal: 0,
    flowType: FlowType.purchase,
  }
)

const emit =
  defineEmits<
    (
      event: 'confirm',
      payload: { selected: any; calculatedAmount: number }
    ) => void
  >()

const {
  pointsBalance,
  burnPointsRatePaymentOrder,
  burnPointsRateBatchOrder,
  burnPointsRateStatementOrder,
  burnPointsRateOrder,
} = usePoints()

const router = useRouter()
const pointsToUse = ref(props.initialValue)

const burnPointsRate = computed(() => {
  switch (props.flowType) {
    case FlowType.payment:
      return burnPointsRatePaymentOrder.value
    case FlowType.batch:
      return burnPointsRateBatchOrder.value
    case FlowType.statement:
      return burnPointsRateStatementOrder.value
  }

  return burnPointsRateOrder.value
})

const totalPointsAmount = computed(() =>
  roundFigure(pointsBalance.value * burnPointsRate.value)
)

const pointsMaxToUse = computed(() => {
  const maxPoints = Math.ceil(props.remainingTotal / burnPointsRate.value)

  return Math.min(maxPoints, Math.max(pointsBalance.value - 1, 0))
})

const calculatedAmount = computed(() => {
  const amount = roundFigure(pointsToUse.value * burnPointsRate.value)

  return Math.min(props.remainingTotal, amount)
})

const amountToPay = computed(
  () => props.remainingTotal - calculatedAmount.value
)

const isConfirmDisabled = computed(() => {
  return pointsToUse.value < 1
})

function onConfirm() {
  emit('confirm', {
    selected: pointsToUse.value,
    calculatedAmount: calculatedAmount.value,
  })
}

function onCancel() {
  router.back()
}
</script>

<template>
  <div class="flex h-full flex-col justify-between">
    <div class="px-5 py-6">
      <div
        class="rounded-xl bg-white p-[30px] md:rounded-none md:bg-transparent"
      >
        <div class="mb-10 flex flex-col items-center">
          <base-icon svg="symbion/token" class="text-primary" :size="72" />
        </div>

        <div class="mb-5 px-2.5 text-center text-eonx-neutral-600">
          Allocate the amount of points you wish to use for this transaction.
        </div>
        <div class="flex w-full items-center">
          <div class="flex grow flex-col gap-[5px]">
            <div class="flex justify-between text-sm text-eonx-neutral-500">
              <div>Points balance available</div>
              <div>Value</div>
            </div>

            <div class="flex justify-between font-bold">
              <div>
                {{ formatPoints(pointsBalance) }}
              </div>
              <div>{{ formatDollar(totalPointsAmount) }}</div>
            </div>
          </div>
        </div>

        <base-range
          v-model="pointsToUse"
          class="mt-12 w-full"
          :min="0"
          :dot-size="32"
          placement="top"
          formatter="{value}"
          :max="pointsMaxToUse"
        />

        <div class="mt-[30px] flex w-full flex-col items-center text-center">
          <span class="font-bold text-eonx-neutral-600">Remaining to pay</span>
          <div class="relative flex items-center gap-[5px] leading-none">
            <span class="text-xl font-bold">$</span>
            <strong class="text-4xl">
              {{ formatAmount(amountToPay) }}
            </strong>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-auto flex w-full shrink-0 justify-center gap-x-5 bg-white p-5 pb-5 shadow-lg"
    >
      <div class="w-full">
        <base-button class="w-full" look="outlined-color" @click="onCancel">
          Cancel
        </base-button>
      </div>
      <div class="w-full">
        <base-button
          data-testid="confirmButton"
          class="w-full"
          :disabled="isConfirmDisabled"
          @click="onConfirm"
        >
          Confirm
        </base-button>
      </div>
    </div>
  </div>
</template>
